import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { notifyError } from "../utils/notifyToast";
import QRCode from "react-qr-code";
import { getPurchaseDetails, getTags } from "../store/actions/discoverActions";

const PrintDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState([]);
  const [items, setItems] = useState([]);
  const [purchaseData, setPurchaseData] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const { token, logo, display_name, google_review_link, tripadvisor_link } =
    useSelector(({ authUser, discover }) => ({
      token: authUser.uid,
      logo: discover.logo,
      display_name: discover.display_name,
      google_review_link: discover.google_review_link,
      tripadvisor_link: discover.tripadvisor_link,
    }));

  useEffect(() => {
    dispatch(getTags(token));
    dispatch(
      getPurchaseDetails(
        params.purchase_number,
        token,
        (deets) => {
          console.log(deets);
          setOrderData(deets.data.order_data);
          setItems(deets.data.items_simplified);
          setPurchaseData(deets.data.purchase_data);
          setUserDetails(deets.data.user_data);
          window.print();
          setTimeout(window.close, 0);
        },
        (err) => {
          notifyError("Failed to fetch payment data. Reload.", {
            position: "bottom-center",
          });
        }
      )
    );
  }, []);

  return (
    <div>
      <div
        style={{
          width: "300px",
          margin: "5px auto",
          paddingLeft: "5px",
          paddingRight: "5px",
          textAlign: "center",
          fontFamily: "monospace",
        }}
      >
        {logo && (
          <img
            style={{
              maxWidth: "80%",
              width: "auto",
              height: "auto",
              maxHeight: "100px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            src={logo}
          />
        )}
        {display_name && (
          <>
            <br />
            <h5
              style={{
                textAlign: "center",
                margin: "4px",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {display_name}
            </h5>
          </>
        )}
        <strong style={{ textAlign: "center" }}>Sharjah Investment and</strong>
        <br />
        <strong style={{ textAlign: "center" }}>
          development Authority Shurooq
        </strong>
        <h3
          style={{
            textAlign: "center",
            margin: "4px",
            fontSize: 15,
            fontWeight: "bold",
            borderBottom: "1px dashed",
          }}
        >
          Tax Invoice
        </h3>
        <span style={{ fontSize: 15, fontWeight: "bold" }}>
          TRN NO: 100391298500003
        </span>
        <br />
        {/* <h5
              style={{
                textAlign: "center",
                margin: "2px",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              Slip : {orderData[0].purchase_number}
            </h5> */}
        {purchaseData?.created_by_name && (
          <span>Staff : {purchaseData.created_by_name}</span>
        )}
        <br />
        <span>Date : {purchaseData?.start_time}</span>
        <br />
        <span>Invoice : {orderData[0]?.purchase_number}</span>
        <br />
        <table
          style={{
            borderBottom: ".5px dashed",
            width: "100%",
          }}
        >
          <tbody>
            <tr
              style={{
                borderTop: "1px dashed",
                borderBottom: "1px dashed",
              }}
            >
              <td colSpan="2">
                <b>Description</b>
              </td>
              <td style={{ textAlign: "right" }}>
                <b>Amount</b>
              </td>
            </tr>
            {/* {orderData.map((order) => (
              <>
                <tr>
                  <td></td>
                  <td style={{ textAlign: "left" }}>
                    {order.package_name} <br />
                    {Number(order.adult_qty) > 0 && (
                      <>Adult: {order.adult_qty}</>
                    )}
                    {Number(order.child_qty) > 0 &&
                      Number(order.adult_qty) > 0 && <>|</>}{" "}
                    {Number(order.child_qty) > 0 && (
                      <>Child: {order.child_qty}</>
                    )}
                    {order.notes && (
                      <>
                        <br />
                        Notes: {order.notes}
                      </>
                    )}
                  </td>
                  <td style={{ whiteSpace: "nowrap", float: "right" }}>
                    {order.sub_total || order.total} AED
                  </td>
                </tr>
                <hr />
              </>
            ))} */}
            {items.map((item) => (
              <>
                <tr>
                  <td></td>
                  <td style={{ textAlign: "left" }}>
                    {item.name} <br />
                    <p
                      style={{ marginLeft:"18px", fontSize: "14px" }}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    {item.notes && (
                      <>
                        <br />
                        (Note: {item.notes})
                      </>
                    )}
                  </td>
                  <td style={{ whiteSpace: "nowrap", float: "right" }}>
                    {item.cost} AED
                  </td>
                </tr>
                <hr />
              </>
            ))}
            <hr />
            <tr
              style={{
                borderTop: "1px dashed",
              }}
            >
              <td></td>
              <td style={{ textAlign: "left" }}>Discount</td>
              <td style={{ whiteSpace: "nowrap", float: "right" }}>
                -
                {orderData.reduce(
                  (sum, order) => Number(order.deducted_value) + sum,
                  0
                )}{" "}
                AED
              </td>
            </tr>
            <hr />
            <tr>
              <td></td>

                    <td style={{ textAlign: "left" }}>
                        Total AED

                                {orderData[0]?.payment_type && (
                                    <>
                                        <br />
                                        <p style={{ marginLeft: "18px", fontSize: "14px", textTransform: "capitalize" }}>
                                            (Paid: {orderData[0].payment_type.replace('tahseel-', '').replace('_manual', '')})
                                        </p>
                                    </>
                                )}

                    </td>





              <td
                style={{
                  whiteSpace: "nowrap",
                  float: "right",
                }}
              >
                {purchaseData?.total} AED
              </td>
            </tr>
            <hr />
          </tbody>
        </table>
        {(userDetails.name || userDetails.mobile) && (
          <table style={{ marginTop: "10px", marginBottom: "25px" }}>
            <tbody>
              {userDetails.name && (
                <tr>
                  <td colSpan="2">
                    <b>Member Name : </b>
                  </td>
                  <td>
                    <b>{userDetails.name}</b>
                  </td>
                </tr>
              )}
              {userDetails.mobile && (
                <tr style={{ textAlign: "left" }}>
                  <td colSpan="2">
                    <b>Phone : </b>
                  </td>
                  <td>
                    <b>{userDetails.mobile}</b>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <strong style={{ textAlign: "center" }}>Thank You!</strong>
        <br />
        <strong style={{ textAlign: "center" }}>Enjoy your visit</strong>
        <br />
        <strong style={{ textAlign: "center" }}>Hope to see you again!</strong>
        <br />
        <span>No refunds on purchases made</span>
        <br />
        <strong style={{ textAlign: "center", marginTop: "1rem" }}>
          Terms and Conditions
        </strong>
        <br />
        <table>
          <tbody>
            <tr>
              <td colSpan="2">
                * <b>Standard booking, cancellation and</b>
                <br />
                <b style={{ marginLeft: "15px" }}>
                  operational policies apply.
                </b>
                <br />* <b>No cash or credit card refunds.</b>
                <br />* <b>Please retain this receipt as proof of</b>
                <br />
                <b style={{ marginLeft: "15px" }}>ticket purchase.</b>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {orderData.map((order) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <QRCode
                size={50}
                style={{
                  height: "auto",
                  maxWidth: "100px",
                  width: "100px",
                }}
                value={order.order_number}
                viewBox={`0 0 50 50`}
              />
              <div style={{ fontSize: 12 }}>{order.package_name}</div>
              <div style={{ fontSize: 12 }}>{order.order_number}</div>
            </div>
          ))}
          {(google_review_link || tripadvisor_link) && (
            <>
              <strong style={{ textAlign: "center" }}>
                If you enjoyed your visit please leave us a review on
              </strong>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                  gap: "2rem",
                }}
              >
                {google_review_link && (
                  <div>
                    <QRCode
                      size={50}
                      style={{
                        height: "auto",
                        maxWidth: "70px",
                        width: "70px",
                      }}
                      value={google_review_link}
                      viewBox={`0 0 50 50`}
                    />
                    <div style={{ fontSize: 12 }}>Google Review</div>
                  </div>
                )}
                {tripadvisor_link && (
                  <div>
                    <QRCode
                      size={50}
                      style={{
                        height: "auto",
                        maxWidth: "70px",
                        width: "70px",
                      }}
                      value={tripadvisor_link}
                      viewBox={`0 0 50 50`}
                    />
                    <div style={{ fontSize: 12 }}>Trip Advisor</div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div style={{ pageBreakAfter: "always" }}></div>
    </div>
  );
};

export default PrintDetails;
